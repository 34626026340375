<template>
  <div>
    <div id="printMe" class="print">
      <div class="invoice">
        <b-table-simple borderless>
          <b-tbody>
            <b-tr class="title">
              <b-td colspan="2" class="text-center"> {{ appName }} </b-td>
            </b-tr>
            <b-tr>
              <b-th> {{ $t("field.name") }}: </b-th>
              <b-td class="bold">
                {{ item.name }}
              </b-td>
            </b-tr>

            <b-tr>
              <b-th> {{ $t("field.username") }}: </b-th>
              <b-td class="bold">
                {{ item.username }}
              </b-td>
            </b-tr>

            <b-tr>
              <th>
                {{
                  item.stateType == "deposit"
                    ? $t("field.deposit")
                    : $t("field.withdraw")
                }}:
              </th>
              <b-td
                class="bold"
                :class="{
                  deposit: item.stateType == 'deposit',
                  withdraw: item.stateType == 'withdrawal',
                }"
              >
                {{ item.amount | currency(item.ccy) }}
              </b-td>
            </b-tr>

            <b-tr>
              <b-th> {{ $t("field.date") }}: </b-th>
              <b-td class="bold">
                {{ item.createdAt | formatDate }}
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </div>
    </div>
    <button
      ref="printBtn"
      id="printBtn"
      v-print="printObj"
      class="d-none"
    ></button>
  </div>
</template>

<script>
import { BTableSimple, BTbody, BTr, BTh, BTd } from "bootstrap-vue";
import { $themeConfig } from "@themeConfig";

export default {
  components: {
    BTableSimple,
    BTbody,
    BTr,
    BTh,
    BTd,
  },
  computed: {
    printObj() {
      return {
        id: "printMe",
        preview: false,
        extraCss: `${window.location.origin}/monaco/stylesheet.css, ${window.location.origin}/print.css`,
      };
    },
  },
  data() {
    return {
      item: {},
    };
  },
  methods: {
    print(item) {
      this.item = { ...item };
      document.getElementById("printBtn").click();
    },
  },
  setup() {
    const { appName } = $themeConfig.app;
    return {
      appName,
    };
  },
};
</script>